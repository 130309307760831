<template>
  <div ref="content" class="content bio" :class="{ transition, expanded }">
    <div class="wrapper">
      <div v-html="content" />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { SET_BIO } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";
import axios from "axios";

export default {
  props: ["directory", "folder", "isActive"],
  data() {
    return {
      content: "Loading...",
      transition: false,
      expanded: false,
    };
  },
  computed: {
    ...mapState(["bio"]),
    ...mapGetters(["transitionEvent"]),
  },
  watch: {
    isActive(val) {
      this.transition = true;
      this.expanded = val;
      this.transitionEvent &&
        this.$refs.content.addEventListener(
          this.transitionEvent,
          this.transitionContentEnd
        );
    },
  },
  methods: {
    fetchContent() {
      axios
        .get("https://nickjeffrey.com/collect.php?id=162&query=text")
        .then((response) => {
          this.content = response.data[0].html;
          store.commit(SET_BIO, this.content);
        })
        .catch((error) => {
          this.content =
            "A problem occured while fetching this request.<br />" + error;
        });
    },
    transitionContentEnd() {
      this.transition = false;
      this.$refs.content.removeEventListener(
        this.transitionEvent,
        this.transitionContentEnd
      );
    },
  },
  mounted() {
    if (this.isActive) this.expanded = true;
    if (this.bio) {
      this.content = this.bio;
    } else {
      this.fetchContent();
    }
  },
};
</script>

<style>
.directory .bio .wrapper {
  padding: 0.75em;
}

.bio header p {
  padding-bottom: 0.75em;
}

.bio h1 {
  margin-top: -5px;
  font-size: 1em;
  font-weight: normal;
  line-height: 2;
}

/*
.bio h1 {
  margin: 2.4rem 0;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
}*/
</style>
